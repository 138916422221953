<template>
  <a-table
    :columns="columns"
    :data-source="roles"
    bordered
    v-if="roles.length > 0"
  >
    <template #title>
      <a-typography-title :level="5">
        Roles: &nbsp;
        <a-tag color="blue">{{ roles.length }}</a-tag>
      </a-typography-title>
    </template>
  </a-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      allRoles: [],
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          width: '50%',
          align: 'center',
        },
        {
          title: 'Name',
          dataIndex: 'name',
          width: '50%',
          align: 'center',
        },
      ],
    };
  },
  created() {
    this.getAllRoles();
  },
  computed: {
    ...mapGetters(['roles']),
  },

  methods: {
    ...mapActions(['getAllRoles']),
  },
};
</script>

<style scoped></style>
