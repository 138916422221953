<template>
  <a-form
    layout="vertical"
    :model="roleName"
    ref="formRef"
    name="add_role"
    @finish="addRole()"
  >
    <div class="row">
      <div class="col-sm-12">
        <a-form-item label="Enter Role" name="roleName">
          <a-input placeholder="ex: admin" v-model:value="roleName"> </a-input>
        </a-form-item>
      </div>
    </div>
    <div class="buttons-w d-flex justify-content-end">
      <a-button type="primary" html-type="submit" :disabled="isRoleExist">
        Create
      </a-button>
    </div>
  </a-form>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AddRole',
  emits: ['toggleModal'],
  data() {
    return {
      roleName: '',
    };
  },
  computed: {
    isRoleExist() {
      return this.roleName === '';
    },
  },
  methods: {
    ...mapActions(['addNewRole']),
    addRole() {
      this.addNewRole(this.roleName);
      this.$emit('toggleModal', false);
    },
  },
};
</script>
